:root {
  --buttonsColor: #1f5bff;
  --buttonsTextColor: #ffffff;
  --buttonsLightColor: #f0f6ff;
  --buttonsTextLightColor: #1f5bff;
  --buttonsDarkColor: #1f5bff;
  --buttonsTextDarkColor: #ffffff;
}

@font-face {
  font-family: 'Flama';
  src: url(./fonts/flama/flama-basic.otf) format('opentype');
}

@font-face {
  font-family: 'Flama Semicondensed';
  src: url(./fonts/flama/flamasemicondensed-basic.otf) format('opentype');
}

body {
  margin: 0;
  font-family: 'Flama', 'Arial', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-style: normal;
  font-weight: 600;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}

html {
  box-sizing: border-box;
  -webkit-tap-highlight-color: transparent;
}

body,
html,
input,
button,
select {
  font-size: 16px;
  line-height: 1.618;
}

button {
  background: transparent;
  border: none;
  cursor: pointer;
}

button:focus {
  outline: none;
}

*,
*:before,
*:after {
  box-sizing: inherit;
}

ul,
ol {
  padding-left: 1em;
}

img {
  max-width: 100%;
  height: auto;
}

a {
  text-decoration: none;
  color: unset;
}

* {
  margin: 0;
  padding: 0;
  position: relative;
  text-rendering: optimizeLegibility;
}

.flex {
  display: flex;
}
.flex-wrap {
  display: flex;
}
.flex-direction-column {
  flex-direction: column;
}

.flex-1 {
  flex: 1;
}

.flex-2 {
  flex: 2;
}

.flex-center {
  align-items: center;
  flex-content: center;
  justify-content: center;
}

.flex-align-center {
  align-items: center;
}

.flex-align-start {
  align-items: flex-start;
}

.flex-align-end {
  align-items: flex-end;
}

.flex-align-stretch {
  align-items: stretch;
}

.flex-content-start {
  align-content: flex-start;
}

.flex-content-end {
  align-content: flex-end;
}

.flex-content-stretch {
  align-content: stretch;
}

.flex-justify-start {
  justify-content: flex-start;
}

.flex-justify-end {
  justify-content: flex-end;
}

.flex-justify-stretch {
  justify-content: stretch;
}

.flex-content-center {
  flex-content: center;
}

.flex-justify-center {
  justify-content: center;
}

h1 {
  font-size: 4.23em;
}

h2 {
  font-size: 24px;
}

h3 {
  font-size: 20px;
}

h4 {
  font-size: 1.272em;
}

h5 {
  font-size: 1.127em;
}

/* App.css */
:root {
  --color-neutral-solid-100: #f0f1f5;
  --color-neutral-solid-600: #848999;
  --color-neutral-solid-700: #676c7a;
  --color-neutral-solid-800: #454852;
  --color-neutral-solid-900: #212329;

  --neutral-transparent-900: rgba(24, 26, 31, 0.9);

  --main-bg-color: #fff;

  --size-1: 18px;
}

html,
body {
  width: 100vw;
  overflow-x: hidden;
}

html {
  background-color: var(--main-bg-color);
}

body {
  font-family: Neusa Next Pro;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 24px;
  color: #33363d;
}

.rf-content-container {
  width: 1200px;
}

.rf-content {
  width: 1088px;
  display: flex;
  margin: 0 auto;
}

.rf-content-spacing {
  background: white;
  max-width: 1200px;
  margin: 0 auto;
}

.rf-content-spacing.-transparent {
  background: none;
}

.rf-pagecontent {
  width: 776px;
  margin: 35px 35px 35px 300px;
  z-index: 1;
  display: flex;
  flex-direction: column;
}

.rf-pagecontent > div {
  margin-top: 40px;
}

.rf-pagecontent > div:first-child {
  margin-top: 0px;
}
.rp-demo {
  background-color: #fff;
  display: inline-block;
  vertical-align: top;

  margin: 1rem;
}

.rp-demo-component {
  padding: 2rem;
}

.rp-demo.-content {
  min-width: 60vw;
  background-color: #fff;
  display: inline-block;
  margin: 1rem;
  padding: 2rem;
}

.rp-demo-props {
  background: #eee;
  color: #999;
  padding: 1rem;
  line-height: 1.4;
  font-size: 0.8rem;
}

pre {
  padding: 1rem;
  background: #eee;
  font-size: 0.618rem;
  line-height: 1.3;
  border-radius: 1rem;
  overflow-x: scroll;
  margin-bottom: 1rem;
}

.ad-big-banner {
  margin: 32px 0;
  z-index: 1;
  border-radius: 16px;
  box-shadow: rgba(0, 0, 0, 0.3) 0 0 0 4px;
  background-color: rgba(0, 0, 0, 0.1);
  backdrop-filter: blur(8px);
  max-width: 500px;
  max-height: 200px;
}
.ad-big-banner img {
  display: block;
  margin: 0 auto;
  border-radius: 16px;
}

/* team badge */

.rp-team-badge-label {
  font-size: 14px;
  line-height: 24px;
  /* or 171% */
  display: flex;
  align-items: center;
  letter-spacing: -0.016em;

  /* Neutral Solid / 700 */
  color: #676c7a;
}

.rp-team-badge-name {
  font-size: 15px;
  line-height: 25px;
  /* or 167% */
  display: flex;
  align-items: center;
  letter-spacing: -0.016em;

  color: #212329;
}

.-focus .rp-team-badge-name {
  font-weight: 500;
  font-size: 18px;
  line-height: 24px;
  /* identical to box height, or 156% */
  display: flex;
  align-items: center;
  letter-spacing: -0.012em;

  /* Neutral Solid / 900 */
  color: #212329;
}

.-focus .rp-team-badge-icon {
  width: auto;
  height: auto;
  max-width: 48px;
  max-height: 48px;
  margin: 0 8px 0 0;
}

.rp-team-badge-icon {
  max-width: 32px;
  max-height: 32px;
  margin: 0 8px 0 8px;
}

.rp-section-title {
  font-family: Neusa Next Pro;
  font-weight: bold;
  font-size: 24px;
  line-height: 32px;
  display: flex;
  align-items: center;
  letter-spacing: -0.008em;
  color: #212329;
  margin-top: 30px;
}

/* SELECTOR */
.rf-selector {
  font-size: 4px;
  line-height: 1;
  height: 32px;
  line-height: 39px;
  letter-spacing: -0.016em;
  border-radius: 8px;
  background-color: #eee;
  display: flex;
}

.rf-selector-element {
  font-family: 'Neusa Next Pro';
  width: 109px;
  text-align: center;
  transition: color 0.3s;
}

.rf-selector-element.-active {
  font-weight: 700;
  color: var(--buttonsTextColor);
}

.rf-selector-active {
  transition: transform 0.1s;

  height: 32px;
  border-radius: 8px;
  background-color: var(--buttonsColor);
  width: 109px;
  position: absolute;
  top: 0;
  left: 0;
}

.-bg-dark-blur {
  background: rgba(24, 26, 31, 0.4);
  backdrop-filter: blur(8px);
}

.-bg-white {
  background: #fff;
}

h2,
h3 {
  font-family: Neusa Next Pro;
  font-style: normal;
  font-weight: bold;
  letter-spacing: -0.008em;
  margin: 24px 0px;
}

.rf-colorbox-container {
  margin: 0 -1px;
}

.rf-colorbox {
  background: #212329;
  border-radius: 12px;
  margin: 0 1px;
  padding: 48px;
  color: #a5aab8;
  font-size: 13px;
  line-height: 19px;
  /* or 146% */
  letter-spacing: -0.016em;
}

.rf-colorbox.-blue {
  background-color: #1041a3;
}

.-txt-align-right {
  text-align: right;
}

.rf-colorbox h1,
.rf-colorbox h2,
.rf-colorbox h3 {
  color: #fff;
}

.rf-colorbox:nth-child(1) {
  border-radius: 12px 0 0 12px;
}
.rf-colorbox:nth-child(2) {
  border-radius: 0 12px 12px 0;
}

@media screen and (max-width: 599px) {
  h2 {
    font-size: 20px;
  }

  .rf-content-container {
    width: 100%;
  }

  .rf-content {
    width: 100%;
  }

  .rp-section-league-selector {
    grid-template-columns: none;
  }

  .rp-section-league-selector.-dropdown {
    margin-top: 0px;
  }

  .rf-pagecontent {
    width: 100%;
    margin: 0;
    padding: 32px 16px 32px 16px;
  }

  .rf-selector {
    margin: 24px 0px;
    max-width: 100%;
  }
}

@media screen and (min-width: 600px) and (max-width: 1135px) {
  h2 {
    font-size: 20px;
  }

  .rf-content-container {
    width: 100%;
  }

  .rf-content {
    width: 100%;
  }

  .rp-section-league-selector {
    grid-template-columns: repeat(3, 1fr);
  }

  .rf-pagecontent {
    width: 100%;
    margin: 0;
    padding: 0px 32px;
  }

  .rf-content-spacing {
    width: 100%;
  }
}
