@font-face {
  font-family: 'refereepro';
  src: url('../font/refereepro.eot?20766990');
  src: url('../font/refereepro.eot?20766990#iefix') format('embedded-opentype'),
       url('../font/refereepro.woff2?20766990') format('woff2'),
       url('../font/refereepro.woff?20766990') format('woff'),
       url('../font/refereepro.ttf?20766990') format('truetype'),
       url('../font/refereepro.svg?20766990#refereepro') format('svg');
  font-weight: normal;
  font-style: normal;
}
/* Chrome hack: SVG is rendered more smooth in Windozze. 100% magic, uncomment if you need it. */
/* Note, that will break hinting! In other OS-es font will be not as sharp as it could be */
/*
@media screen and (-webkit-min-device-pixel-ratio:0) {
  @font-face {
    font-family: 'refereepro';
    src: url('../font/refereepro.svg?20766990#refereepro') format('svg');
  }
}
*/
 
 [class^="icon-"]:before, [class*=" icon-"]:before {
  font-family: "refereepro";
  font-style: normal;
  font-weight: normal;
  speak: never;
 
  display: inline-block;
  text-decoration: inherit;
  width: 1em;
  margin-right: .2em;
  text-align: center;
  /* opacity: .8; */
 
  /* For safety - reset parent styles, that can break glyph codes*/
  font-variant: normal;
  text-transform: none;
 
  /* fix buttons height, for twitter bootstrap */
  line-height: 1em;
 
  /* Animation center compensation - margins should be symmetric */
  /* remove if not needed */
  margin-left: .2em;
 
  /* you can be more comfortable with increased icons size */
  /* font-size: 120%; */
 
  /* Font smoothing. That was taken from TWBS */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
 
  /* Uncomment for 3D effect */
  /* text-shadow: 1px 1px 1px rgba(127, 127, 127, 0.3); */
}
 
.icon-right-small:before { content: '\e800'; } /* '' */
.icon-up-down:before { content: '\e801'; } /* '' */
.icon-down-open-mini:before { content: '\e802'; } /* '' */
.icon-left-open-mini:before { content: '\e803'; } /* '' */
.icon-right-open-mini:before { content: '\e804'; } /* '' */
.icon-up-open-mini:before { content: '\e805'; } /* '' */
.icon-share:before { content: '\e806'; } /* '' */
.icon-location-outline:before { content: '\e807'; } /* '' */
.icon-left-small:before { content: '\e808'; } /* '' */
.icon-search:before { content: '\e809'; } /* '' */
.icon-cancel:before { content: '\e80a'; } /* '' */
.icon-subs:before { content: '\e80b'; } /* '' */
.icon-teams:before { content: '\e80c'; } /* '' */
.icon-competition:before { content: '\e80d'; } /* '' */
.icon-dot:before { content: '\e80e'; } /* '' */
.icon-whistle:before { content: '\e80f'; } /* '' */
.icon-download:before { content: '\e810'; } /* '' */
.icon-right-arrow:before { content: '\e811'; } /* '' */
.icon-phone:before { content: '\e812'; } /* '' */
.icon-info-circled-alt:before { content: '\f086'; } /* '' */
.icon-twitter:before { content: '\f099'; } /* '' */
.icon-facebook:before { content: '\f09a'; } /* '' */
.icon-menu:before { content: '\f0c9'; } /* '' */
.icon-mail-alt:before { content: '\f0e0'; } /* '' */
.icon-youtube:before { content: '\f16a'; } /* '' */
.icon-instagram:before { content: '\f16d'; } /* '' */
.icon-twitter-squared:before { content: '\f304'; } /* '' */
.icon-facebook-squared:before { content: '\f308'; } /* '' */