.contact-view-main-container {
  background: #ffffff;
  width: 100%;
  display: flex;
  align-items: flex-start;
  justify-content: center;
  padding: 60px 40px;
}

.contact-view-grid-container {
  width: 100%;
  display: grid;
  grid-template-columns: 1.7fr 1fr;
  grid-gap: 0px 90px;
}

.contact-view-info-container {
  width: 100%;
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  flex-direction: column;
}

.contact-view-greetings-text {
  font-family: Neusa Next Pro;
  font-style: italic;
  font-weight: 300;
  font-size: 24px;
  line-height: 28px;
  letter-spacing: -0.4px;
  color: #212329;
}

.contact-view-organization-name-text {
  margin-top: 15px;
  font-family: Neusa Next Pro;
  font-style: normal;
  font-weight: bold;
  font-size: 52px;
  line-height: 56px;
  letter-spacing: -1.6px;
  color: #212329;
  text-transform: uppercase;
}

.contact-view-organization-services-container {
  margin-top: 20px;
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  flex-direction: column;
}

.contact-view-organization-text {
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 24px;
  letter-spacing: -0.16px;
  color: #454852;
  text-align: justify;
}

.contact-view-organization-text.-desc-separation {
  margin-top: 25px;
  min-height: 70px;
}

.contact-view-organization-text.-service-separation {
  margin-bottom: 4px;
}

.contact-view-organization-title {
  font-style: normal;
  font-weight: 500;
  font-size: 20px;
  line-height: 28px;
  letter-spacing: -0.2px;
  color: #212329;
}

.contact-view-organization-title.-service-title-separation {
  margin-top: 22px;
  margin-bottom: 10px;
}

.contact-view-organization-title.-contact-us-title-separation {
  margin-top: 40px;
  margin-bottom: 17px;
}

.contact-view-slider-main-container {
  margin-top: 45px;
  width: 496px;
  height: 340px;
  overflow: hidden;
  border-radius: 8px;
}

.contact-view-slider-container {
  width: 100%;
  height: 100%;
  overflow: hidden;
  z-index: 10;
}

.contact-view-slide {
  position: absolute;
  width: 100%;
  height: 100%;
  will-change: transform;
}

.contact-view-photo-contact-container {
  width: 100%;
  height: 100%;
}

.contact-view-photo-container {
  height: 100%;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.contact-view-photo-contact {
  max-height: 100%;
  max-width: 100%;
  object-fit: cover;
  object-position: center;
  user-select: none;
  -moz-user-select: none;
  -webkit-user-drag: none;
  -webkit-user-select: none;
  -ms-user-select: none;
}

.contact-view-slider-buttons-container {
  position: absolute;
  display: flex;
  flex-direction: row;
  bottom: 19.5px;
  right: 19.5px;
  z-index: 20;
}

.contact-view-slider-button {
  cursor: pointer;
  background: #ffffff;
  border-radius: 5px;
  margin: 4.5px;
  width: 32px;
  height: 32px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.contact-view-slider-button-arrow {
  color: #454852;
}

.contact-view-contact-data {
  width: 100%;
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  flex-direction: column;
}

.contact-view-contact-info-container {
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  flex-direction: row;
  margin-bottom: 13px;
  text-decoration: none;
}

.contact-view-contact-data-container {
  width: 100%;
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  flex-direction: column;
}

.contact-view-contact-data-icons {
  color: #a5aab8;
  margin-right: 17px;
}

.contact-view-map-top-separator {
  height: 80px;
}

.contact-view-map-container {
  position: absolute;
  bottom: 0;
  width: 100%;
  height: 320px;
  background: linear-gradient(180deg, #1f5bff 0%, #041c5d 64.58%, #790461 100%);
}

.contact-view-map-container > iframe {
  border: 0;
}

@media screen and (max-width: 599px) {
  .contact-view-grid-container {
    grid-template-columns: 1fr;
    grid-gap: 0px;
  }

  .contact-view-map-container {
    position: static;
  }
  .contact-view-contact-data-container {
    display: flex;
  }

  .contact-view-slider-main-container {
    width: 100%;
  }

  .contact-view-slider-main-container {
    width: 100%;
    height: 204px;
  }
}

@media screen and (min-width: 600px) and (max-width: 1135px) {
  .contact-view-grid-container {
    grid-template-columns: 1fr;
    grid-gap: 0px;
  }

  .contact-view-map-container {
    position: static;
  }

  .contact-view-contact-data-container {
    display: grid;
    grid-template-columns: 0.4fr 1fr;
  }

  .contact-view-slider-main-container {
    width: 100%;
  }
}
